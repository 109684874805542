import { Box, Button, Center, Heading, VStack } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import styles from "./DevicePermission.module.css";
import { connect, ConnectedProps } from "react-redux";
import { updateBlock } from "../../../reducers/block";
import blockService from "../../../services/block.service";
declare var facialEye: any;

interface DevicePermissionProps extends PropsFromRedux {}

const DevicePermission: FC<DevicePermissionProps> = (props: any) => {
  const [loadSDK, setLoadSDK] = useState(true);

  useEffect(() => {
    if (loadSDK) {
      let error = facialEye.init_fcet("", "");
      setLoadSDK(false);
      error.then((error: any) => {
        console.log("error is: ", error);
      });
    }
  }, [loadSDK]);

  const getNextBlock = () => {
    blockService
      .getNextBlock(props.study.study_id, props.block.block_id)
      .then((blockRes: any) => {
        props.updateBlock(blockRes.data.data.block);
        console.log(blockRes);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  return (
    <div className={styles.DevicePermission} data-testid="DevicePermission">
      <Center h="100vh">
        <VStack>
          <Heading>Device Permission</Heading>
          <Button onClick={() => getNextBlock()}>Next</Button>
        </VStack>
      </Center>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    study: state.study.study,
    block: state.block.block,
  };
};

const mapDispatchToProp = (dispatch: any) => {
  return {
    updateBlock: (payload: any) => {
      dispatch(updateBlock(payload));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProp);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(DevicePermission);
