import { Box, Button, Center, Heading, VStack } from "@chakra-ui/react";
import { FC } from "react";
import styles from "./Instructions.module.css";
import { connect, ConnectedProps } from "react-redux";
import { updateBlock } from "../../../reducers/block";
import blockService from "../../../services/block.service";

interface InstructionsProps extends PropsFromRedux {}

const Instructions: FC<InstructionsProps> = (props: any) => {
  const getNextBlock = () => {
    blockService
      .getNextBlock(props.study.study_id, props.block.block_id)
      .then((blockRes: any) => {
        props.updateBlock(blockRes.data.data.block);
        console.log(blockRes);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  return (
    <div className={styles.Instructions} data-testid="Instructions">
      <Box>
        <Center h="100vh">
          <VStack>
            <Heading>Tech Instructions</Heading>
            <Button onClick={() => getNextBlock()}>Next</Button>
          </VStack>
        </Center>
      </Box>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    study: state.study.study,
    block: state.block.block,
  };
};

const mapDispatchToProp = (dispatch: any) => {
  return {
    updateBlock: (payload: any) => {
      dispatch(updateBlock(payload));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProp);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Instructions);
