import { api } from "../interceptor/interceptor";
import { Constants } from "../utility/constant";

class BlockService {
  getNextBlock(sId: any, bId: any) {
    return api.get(
      Constants.GET_NEXT_BLOCK +
        "?study_id=" +
        sId +
        "&previous_block_id=" +
        bId
    );
  }

  getSignedURL(sId: any, tId: any, bId: any, tech: string) {
    return api.get(
      Constants.GET_SIGNED_URL_SDK +
        "?study_id=" +
        sId +
        "&tester_id=" +
        tId +
        "&block_id=" +
        bId +
        "&tech=" +
        tech
    );
  }
}
export default new BlockService();
