import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  shadows: {
    outline: "none",
  },
  colors: {
    text: "#2E2E2E",
    brand: {
      100: "#00205B",
      500: "#00205B",
    },
    audio: {
      500: "#9ce9fd",
    },
    video: {
      500: "#a6f2cd",
    },
    drawer: {
      100: "red",
    },

    switch: {
      500: "#64DAA1",
    },
    french_pass: {
      500: "#BBE7FE",
      200: "#1BC9FD",
    },
    thistle: {
      500: "#D3B5E5",
      200: "#8C7C8D",
    },
    pastel_pink: {
      500: "#FFD4DB",
      200: "#FF878D",
    },
    loafer: {
      500: "#EFF1DB",
      200: "#A7AB9B",
    },
    tequila: {
      500: "#FBE7C6",
      200: "#FF9717",
    },
    pale_turquoise: {
      500: "#B4F8C8",
      200: "#679C84",
    },
    water_leaf: {
      500: "#A0E7E5",
      200: "#639089",
    },
    soft_pink: {
      500: "#FFAEBC",
      200: "#FD194A",
    },
    tropical_blue: {
      500: "#CDDAFD",
      200: "#1785FB",
    },
    misty_rose: {
      500: "#FDE2E4",
      200: "#FF6554",
    },
    default: {
      500: "#A2DBFA",
      200: "#025DF4",
    },
  },
  fonts: {
    heading: "Manrope",
    body: "Manrope",
    button: "Manrope",
    fontSize: "sm",
  },
  fontSizes: {
    md: "14px",
    fs16: "16px",
    fs10: "10px",
  },
  components: {
    Button: {
      // 1. We can update the base styles
      baseStyle: {
        fontWeight: "bold",
        _focus: {
          boxShadow: "none",
        },
      },
      // 2. We can add a new button size or extend existing
      sizes: {
        xl: {
          h: "56px",
          fontSize: "lg",
          px: "32px",
        },
      },
      // 3. We can add a new visual variant
      variants: {
        "with-shadow": {
          bg: "transparent linear-gradient(90deg, #64DAA1 0%, #00B4E4 100%) 0% 0% no-repeat padding-box",
          boxShadow: "0 3px 10px #0000001a",
          borderRadius: "4px",
        },
        "without-gradient": {
          bg: "#FFFFFF",
          color: "#00205B",
          borderRadius: "4px",
        },
        "action-no-bg": {
          bg: "#FFFFFF",
          color: "#00B4E4",
          borderRadius: "4px",
          border: "1px solid #00B4E4",
          padding: "0 25px",
          height: "30px",
          minWidth: "90px",
        },
        "action-no-bg-noMinWidth": {
          bg: "#FFFFFF",
          color: "#00B4E4",
          borderRadius: "4px",
          border: "1px solid #00B4E4",
          padding: "0 25px",
          height: "30px",
        },
        "action-btn-primary": {
          bg: "#00b4e4",
          color: "#FFFFFF",
          borderRadius: "4px",
          border: "1px solid #00B4E4",
          padding: "0 25px",
          height: "40px",
          _hover: {
            bg: "#00b4e4 !important",
            color: "#FFFFFF",
          },
          _disabled: {
            bg: "#00b4e4",
            color: "#FFFFFF",
          },
        },
        "action-btn": {
          bg: "transparent linear-gradient(90deg, #64DAA1 0%, #00B4E4 100%) 0% 0% no-repeat padding-box",
          borderRadius: "4px",
          padding: "0 25px",
          height: "30px",
          color: "#FFFFFF",
          minWidth: "90px",
          _hover: {
            bg: "transparent linear-gradient(90deg, #64DAA1 0%, #00B4E4 100%) 0% 0% no-repeat padding-box",
            boxShadow: "0 3px 10px #0000001a",
            color: "#FFFFFF",
          },
          _active: {
            color: "#00205B",
          },
        },
        "action-btn-noMinWidth": {
          bg: "transparent linear-gradient(90deg, #64DAA1 0%, #00B4E4 100%) 0% 0% no-repeat padding-box",
          borderRadius: "4px",
          padding: "0 25px",
          height: "30px",
          color: "#FFFFFF",
          _hover: {
            bg: "transparent linear-gradient(90deg, #64DAA1 0%, #00B4E4 100%) 0% 0% no-repeat padding-box",
            boxShadow: "0 3px 10px #0000001a",
            color: "#FFFFFF",
          },
          _active: {
            color: "#00205B",
          },
        },
        "blue-background": {
          borderRadius: "0px",
          height: "30px",
          minWidth: "90px",
          margin: "0px",
          border: "1px solid #C9C9C9",
          padding: "0 25px",
        },
        search: {
          bg: "#FFFFFF",
          color: "#00205B",
          borderRadius: "4px",
          width: "100%",
          justifyContent: "left",
          _focus: {
            boxShadow: "none",
          },
        },
        clipBoard: {
          bg: "#FFFFFF",
          borderRadius: "4px",
          border: "1px solid #cccccc",
          height: "30px",
          minWidth: "90px",
          color: "inherit",
          fontWeight: "normal",
          padding: "18px 10px",
        },
        clipBoardInsights: {
          bg: "#FFFFFF",
          borderRadius: "4px",
          height: "30px",
          minWidth: "90px",
          color: "#00205B",
          fontWeight: "normal",
          padding: "18px 10px",
        },
        // 4. We can override existing variants
        solid: () => ({
          bg: "#6F73D2;",
          borderRadius: "4px",
          padding: "0 25px",
          height: "40px",
          color: "#FFFFFF",
          _hover: {
            bg: "#6F73D2",
            color: "#FFFFFF",
            transform: "scale(0.97)",
            bgGradient: "linear(to-r, primary.500, primary.400)",
          },
          _active: {
            bg: "#9093d3",
            color: "#FFFFFF",
          },
          _focus: {},
          _loading: {
            bg: "#6F73D2",
            color: "#FFFFFF",
            _hover: {
              bg: "#6F73D2",
              color: "#FFFFFF",
            },
          },
        }),
        "gray-action-btn": {
          padding: "0px 5px",
          height: "30px",
          color: "#BEBEBE",
          bg: "#FFFFFF",
          borderRadius: "4px",
          border: "1px solid #E2E2E2",
          fontSize: "12px",
          position: "relative",
        },
      },
    },
  },
  styles: {
    global: {
      // styles for the `body`
      body: {
        color: "#414042",
        fontSize: "sm",
        background: "#f4f7fc",
        overflow: "hidden",
      },
    },
  },
});

export default theme;
