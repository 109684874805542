import { createSlice } from "@reduxjs/toolkit";

interface StudyInterface {
  study: any;
}

const initialState: StudyInterface = {
  study: {
    preview: false,
    study_name: "Untitled",
  },
};

export const studySlice = createSlice({
  name: "study",
  initialState,
  reducers: {
    updateStudy: (state, action) => {
      const data = { ...state.study, ...action.payload };
      state.study = data;
    },
  },
});

export const { updateStudy } = studySlice.actions;

export default studySlice.reducer;
