import ErrorPage from "./components/organisms/ErrorPage/ErrorPage";
import FacialCoding from "./components/organisms/FacialCoding/FacialCoding";
import PageNotFound from "./components/templates/PageNotFound/PageNotFound";
import StudyBlocks from "./components/organisms/StudyBlocks/StudyBlocks";
import ThankYouPage from "./components/organisms/ThankYouPage/ThankYouPage";
import LandingPage from "./components/templates/LandingPage/LandingPage";

export const routes = [
  {
    routeProps: {
      path: "/study/:dc/:wid/:sid",
      exact: true,
      element: LandingPage,
    },
    name: "LandingPage",
  },
  {
    routeProps: {
      path: "/study/:dc/:wid/:sid/preview",
      exact: true,
      element: LandingPage,
    },
    name: "LandingPage",
  },
  {
    routeProps: {
      path: "/thankyou",
      exact: true,
      element: ThankYouPage,
    },
    name: "ThankYouPage",
  },
  {
    routeProps: {
      path: "/error",
      exact: true,
      element: ErrorPage,
    },
    name: "ErrorPage",
  },
  {
    routeProps: {
      path: "/study-blocks",
      exact: true,
      element: StudyBlocks,
    },
    name: "StudyBlocks",
  },
  {
    routeProps: {
      path: "/facial-coding",
      exact: true,
      element: FacialCoding,
    },
    name: "FacialCoding",
  },
  {
    routeProps: {
      path: "*",
      element: PageNotFound,
    },
    name: "PageNotFound",
  },
];
