import { createSlice } from "@reduxjs/toolkit";

interface Tester {
  tester_id: string;
  tester_name: string;
}

const initialState: Tester = {
  tester_id: "",
  tester_name: "",
};

export const testerSlice = createSlice({
  name: "tester",
  initialState,
  reducers: {
    updateTesterID: (state, action) => {
      state.tester_id = action.payload;
    },
    updateTesterName: (state, action) => {
      state.tester_name = action.payload;
    },
  },
});

export const { updateTesterID, updateTesterName } = testerSlice.actions;

export default testerSlice.reducer;
