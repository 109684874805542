import { FC, useEffect, useRef, useState } from "react";
import styles from "./GetUserCamera.module.css";

interface GetUserCameraProps {}

const GetUserCamera: FC<GetUserCameraProps> = () => {
  const videoRef = useRef(null);
  const photoRef = useRef(null);
  const [video, setVideo] = useState({} as any);
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    if (videoRef.current && !videoLoaded) {
      getVideo();
      setVideoLoaded(true);
    }
  });

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: { width: 300 } })
      .then((stream) => {
        if (videoRef.current) {
          let video: any = videoRef.current;
          video.srcObject = stream;
          video.play();
          setVideo(video);
        }

        // convet to canvas
      })
      .catch((err) => {
        console.error("error:", err);
      });
  };

  const stop = () => {
    const stream = video.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      for (let i = 0; i < tracks.length; i++) {
        let track = tracks[i];
        track.stop();
      }
    }

    video.srcObject = null;
  };

  const paintToCanvas = () => {
    let video = videoRef.current;
    let photo: any = photoRef.current;
    let ctx = photo.getContext("2d");

    const width = 320;
    const height = 240;
    photo.width = width;
    photo.height = height;
    ctx.drawImage(video, 0, 0, width, height);

    setTimeout(() => {
      paintToCanvas();
    }, 0);
  };

  return (
    <div className={styles.GetUserCamera} data-testid="GetUserCamera">
      <button onClick={stop}>Stop</button>
      <video onCanPlay={() => paintToCanvas()} ref={videoRef} />
      <canvas id="faceCam" ref={photoRef} />
    </div>
  );
};

export default GetUserCamera;
