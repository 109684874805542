import { Center, Spinner, useToast } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { updateStudy } from "../../../reducers/study";
import authService from "../../../services/auth.service";
import WelcomePage from "../../organisms/WelcomePage/WelcomePage";
import styles from "./LandingPage.module.css";
import { connect, ConnectedProps } from "react-redux";
import { updateBlock } from "../../../reducers/block";
import { useNavigate } from "react-router-dom";

type LandingRouteParams = {
  sid: string;
  wid: string;
};

interface LandingPageProps extends PropsFromRedux {}

const LandingPage: FC<LandingPageProps> = (props: any) => {
  const [showLoader, setShowLoader] = useState(true);
  const LandingQueryParams = useParams<LandingRouteParams>();
  const [isPreview] = useState(window.location.pathname.includes("preview"));
  const [studyData, setStudyData] = useState({} as any);
  const toast = useToast();
  const navigate = useNavigate();

  // token validation

  useEffect(() => {
    const validationPayload = {
      study_id: LandingQueryParams.sid,
      preview: isPreview,
    };
    sessionStorage.setItem(
      "workspaceId",
      JSON.stringify(LandingQueryParams.wid)
    );
    props.updateStudy(validationPayload);
    authService
      .validateToken(validationPayload)
      .then((response: any) => {
        setShowLoader(false);
        console.log(response.data.data);
        setStudyData(response.data.data);
        props.updateStudy(response.data.data.study);
        props.updateBlock(response.data.data.welcome_block);
      })
      .catch((err: any) => {
        console.log(err);
        toast({
          title: "Oops!",
          description: err.response.data.message,
          status: "error",
          position: "top-right",
          variant: "left-accent",
          isClosable: true,
        });
        navigate("/error");
      });
  }, [toast, LandingQueryParams, isPreview]);

  // token validation

  return (
    <div className={styles.LandingPage} data-testid="LandingPage">
      {showLoader ? (
        <Center h="100vh">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Center>
      ) : (
        <WelcomePage blockData={studyData.welcome_block} />
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    study: state.study.study,
  };
};

const mapDispatchToProp = (dispatch: any) => {
  return {
    updateStudy: (payload: any) => {
      dispatch(updateStudy(payload));
    },
    updateBlock: (payload: any) => {
      dispatch(updateBlock(payload));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProp);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(LandingPage);
