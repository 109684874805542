import { createSlice } from "@reduxjs/toolkit";

interface BlockInterface {
  block: any;
}

const initialState: BlockInterface = {
  block: {
    block_id: 0,
    block_type: "",
    block_properties: {
      image: "https://cdn.getdecode.io/media/images/Thankyou.png",
      settings: {
        language: "english",
      },
      action_button_text: "Start Preview",
      block_type: "THANKYOU_PAGE",
      description: "lorem ipsum dolor",
      style: {
        alignment: "left",
        background_color: "#fefefe",
        cta_bg_color: "#abcdef",
      },
      title: "Thank you Page",
    },
  },
};

export const blockSlice = createSlice({
  name: "block",
  initialState,
  reducers: {
    updateBlock: (state, action) => {
      state.block = action.payload;
    },
  },
});

export const { updateBlock } = blockSlice.actions;

export default blockSlice.reducer;
