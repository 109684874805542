import "./App.css";
import { routes } from "./routes";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Box } from "@chakra-ui/react";

function App() {
  return (
    <div className="App">
      <Router>
        <Box className="root-container">
          <Routes>
            {routes.map((route: any, i) => {
              const Element = route.routeProps.element;
              return (
                <>
                  <Route
                    key={route.name}
                    path={route.routeProps.path}
                    element={<Element />}
                  />
                </>
              );
            })}
          </Routes>
        </Box>
      </Router>
    </div>
  );
}

export default App;
