import React, { FC } from "react";
import styles from "./ErrorPage.module.css";
import { Box, HStack, Image } from "@chakra-ui/react";

interface ErrorPageProps {}

const ErrorPage: FC<ErrorPageProps> = () => {
  const initData = {
    block_properties: {
      image: "https://cdn.getdecode.io/media/images/Error.png",
      settings: {
        language: "english",
      },
      action_button_text: "Start Preview",
      block_type: "ERROR_PAGE",
      description: "lorem ipsum dolor",
      style: {
        alignment: "left",
        background_color: "#fefefe",
        cta_bg_color: "#abcdef",
      },
      title: "Error Page",
    },
  };

  return (
    <div className={styles.ErrorPage} data-testid="ErrorPage">
      <Box>
        <HStack className={styles["page-stack"]}>
          <Box className={styles["page-stack-left"]}>
            <Image
              className={styles["page-stack-left__image"]}
              src={initData.block_properties.image}
              alt="landing-image"
            ></Image>
          </Box>
          <Box className={styles["page-stack-right"]}>
            <Box className={styles["page-title"]}>
              {initData.block_properties.title}
            </Box>
            <Box className={styles["page-desc"]}>
              {initData.block_properties.description}
            </Box>
          </Box>
        </HStack>
      </Box>
    </div>
  );
};

export default ErrorPage;
