import { FC, useEffect, useState } from "react";
import styles from "./VideoPlayer.module.css";
import ReactPlayer from "react-player";
import FacialCoding from "../FacialCoding/FacialCoding";
import { connect, ConnectedProps } from "react-redux";
import { updateBlock } from "../../../reducers/block";
import blockService from "../../../services/block.service";
import { useNavigate } from "react-router-dom";
import axios from "axios";

declare var facialEye: any;

interface VideoPlayerProps extends PropsFromRedux {}

const VideoPlayer: FC<VideoPlayerProps> = (props: any) => {
  const [mute, setMute] = useState(true);
  const [playing] = useState(true);
  const [sdkInteraval, setSdkInterval] = useState({} as any);
  const [videoURL, setVideoURL] = useState("");
  const [facialData, setFacialData] = useState([] as any);
  const width = 320;
  const height = 240;
  const youtube = "https://www.youtube.com/watch?v=uE_SEbV8lLM";
  const vimeo = "https://vimeo.com/90509568";
  const mp4 =
    "https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4";
  const navigate = useNavigate();
  const config = {
    youtube: {
      playerVars: { disablekb: 1 },
    },
    vimeo: {
      playerOptions: { keyboard: false },
    },
  };

  useEffect(() => {
    setVideoURL(props.block.block_properties.media_url);
  }, [mute]);

  const playerStart = () => {
    console.log("start");
    setMute(false);
    const isFirst = true;

    setSdkInterval(
      setInterval(() => {
        const canvasID: any = window.document.getElementById("faceCam");
        let ctx = canvasID.getContext("2d");
        let imgData = ctx.getImageData(0, 0, width, height);
        let uint8ArrData = new Uint8Array(imgData.data);
        console.log("get emotions------>");
        const emotions = facialEye.test_emotion_image(
          uint8ArrData,
          width,
          height,
          isFirst
        );
        console.log(emotions);
        setFacialData((prevState: any) => [...prevState, emotions]);
      }, 1000)
    );
  };

  const playerEnd = () => {
    console.log("ended");
    clearInterval(sdkInteraval);
    console.log(facialData);
    // navigate("/error");
    uploadDataToURL();
    getNextBlock();
  };

  const uploadDataToURL = () => {
    blockService
      .getSignedURL(
        props.study.study_id,
        props.tester.tester_id,
        props.block.block_id,
        "fc"
      )
      .then((res) => {
        uploadJSONContent(res.data.data.tester_response.fc_s3_key);
      });
  };
  const uploadJSONContent = (url: any) => {
    const document = facialData;
    console.log(document);
    const request = axios
      .put(url, document, {
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response && error.response.status === 403) {
          uploadJSONContent(document);
        }
      });
  };

  const getNextBlock = () => {
    blockService
      .getNextBlock(props.study.study_id, props.block.block_id)
      .then((blockRes: any) => {
        props.updateBlock(blockRes.data.data.block);
        console.log(blockRes);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  return (
    <div className={styles.VideoPlayer} data-testid="VideoPlayer">
      <div className={styles["player-wrapper"]}>
        <ReactPlayer
          onReady={() => console.log("onReady")}
          key="player"
          className={styles["react-player"]}
          url={videoURL}
          width="100%"
          height="100%"
          onStart={() => playerStart()}
          onEnded={() => playerEnd()}
          playing={playing}
          muted={mute}
          pip={false}
          controls={false}
          config={config}
        />
      </div>
      <FacialCoding />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    study: state.study.study,
    block: state.block.block,
    tester: state.tester,
  };
};

const mapDispatchToProp = (dispatch: any) => {
  return {
    updateBlock: (payload: any) => {
      dispatch(updateBlock(payload));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProp);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(VideoPlayer);
