import { api } from "../interceptor/interceptor";
import { Constants } from "../utility/constant";

class AuthService {
  validateToken(payload: any) {
    return api.post(Constants.VALIDATE_TOKEN, payload);
  }

  generateToken(payload: any) {
    return api.post(Constants.GENERATE_TOKEN, payload);
  }
}
export default new AuthService();
