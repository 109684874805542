import { Center, Image } from "@chakra-ui/react";
import React, { FC } from "react";
import styles from "./PageNotFound.module.css";
import pageNotFound from "./../../../assets/images/pageNotFound.png";

interface PageNotFoundProps {}

const PageNotFound: FC<PageNotFoundProps> = () => (
  <div className={styles.PageNotFound} data-testid="PageNotFound">
    <Center h="100vh">
      <Image src={pageNotFound} alt="404" />
    </Center>
  </div>
);

export default PageNotFound;
