import { Box } from "@chakra-ui/react";
import { FC, useEffect } from "react";
import { updateBlock } from "../../../reducers/block";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import styles from "./StudyBlocks.module.css";
import { connect, ConnectedProps } from "react-redux";
import DevicePermission from "../DevicePermission/DevicePermission";
import Instructions from "../Instructions/Instructions";
import SurveyComponent from "../SurveyComponent/SurveyComponent";
import ThankYouPage from "../ThankYouPage/ThankYouPage";
import ErrorPage from "../ErrorPage/ErrorPage";
import CollectInfo from "../CollectInfo/CollectInfo";
import TermsAndCondition from "../TermsAndCondition/TermsAndCondition";

interface StudyBlocksProps extends PropsFromRedux {}

const StudyBlocks: FC<StudyBlocksProps> = (props: any) => {
  useEffect(() => {
    renderSwitch();
  }, [props.block]);

  const renderSwitch = () => {
    switch (props.block.block_type) {
      case "TERMS_AND_CONDITIONS":
        return <TermsAndCondition />;
      case "PRE_STUDY":
        return <CollectInfo />;
      case "FC_INSTRUCTIONS":
        return <Instructions />;
      case "DEVICE_PERMISSIONS":
        return <DevicePermission />;
      case "MCQ_BLOCK":
      case "CHECKBOX":
      case "PARAGRAPH":
      case "SHORT_ANSWER":
        return <SurveyComponent />;
      case "THANKYOU_PAGE":
        return <ThankYouPage />;
      case "ERROR_PAGE":
        return <ErrorPage />;
      case "YOUTUBE_MEDIA":
      case "VIMEO_MEDIA":
        return <VideoPlayer />;
      default:
        return <ErrorPage />;
    }
  };

  return (
    <div className={styles.StudyBlocks} data-testid="StudyBlocks">
      <Box>{renderSwitch()}</Box>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    study: state.study.study,
    block: state.block.block,
  };
};

const mapDispatchToProp = (dispatch: any) => {
  return {
    updateBlock: (payload: any) => {
      dispatch(updateBlock(payload));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProp);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(StudyBlocks);
