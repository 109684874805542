import axios from "axios";
import utilityService from "../services/utility.service";

const ENV = utilityService.getENV();
const dc = window.location.pathname.split("/")[2];
// set base url in the Parent
export const api = axios.create({
  baseURL: "https://" + dc + "." + ENV + ".apicx.getdecode.io/v1/",
});

// move into functions
api.interceptors.request.use(
  function (config: any) {
    // Do something before request is sent
    // config.headers = 'My ConverZ Demo';
    const wid = sessionStorage.getItem("workspaceId")
      ? JSON.parse(sessionStorage.getItem("workspaceId") as string)
      : false;
    config.headers.workspace_id = wid
      ? wid
      : window.location.pathname.split("/")[3];
    return config;
  },
  function (error) {
    // Do something with request error
    console.log(error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    console.log(error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (
      error.response.data.message === "Access Token has been expired" &&
      error.response.status === 401
    ) {
      console.log("Expired");
    }

    // specific error handling done elsewhere
    console.log(error);
    return Promise.reject(error);
  }
);
