import { FC, useState } from "react";
import styles from "./WelcomePage.module.css";
import { Box, Button, HStack, Image, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import authService from "../../../services/auth.service";
import { updateTesterID, updateTesterName } from "../../../reducers/tester";
import { updateBlock } from "../../../reducers/block";
import blockService from "../../../services/block.service";

interface WelcomePageProps extends PropsFromRedux {
  blockData: any;
}

const WelcomePage: FC<WelcomePageProps> = (props: any) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [loadingTesterId, setLoadingTesterId] = useState(false);

  const generateTesterID = () => {
    setLoadingTesterId(true);
    const payload = {
      study_id: props.study.study_id,
      workspace_id: props.study.workspace_id,
      preview: props.study.preview,
    };
    authService
      .generateToken(payload)
      .then((response: any) => {
        console.log(response);
        const studyData = response.data.data;
        props.updateTesterID(studyData.tester.tester_id);
        props.updateTesterName(studyData.tester.tester_name);
        blockService
          .getNextBlock(props.study.study_id, props.block.block_id)
          .then((blockRes: any) => {
            setLoadingTesterId(false);
            props.updateBlock(blockRes.data.data.block);
            navigate("/study-blocks");
            console.log(blockRes);
          })
          .catch((err: any) => {
            toast({
              title: "Oops!",
              description: err.response.data.message,
              status: "error",
              position: "top-right",
              variant: "left-accent",
              isClosable: true,
            });
            setLoadingTesterId(false);
            console.log(err);
          });
      })
      .catch((err: any) => {
        console.log(err);
        toast({
          title: "Oops!",
          description: err.response.data.message,
          status: "error",
          position: "top-right",
          variant: "left-accent",
          isClosable: true,
        });
      });
  };

  return (
    <div className={styles.WelcomePage} data-testid="WelcomePage">
      <Box>
        <HStack className={styles["page-stack"]}>
          <Box className={styles["page-stack-left"]}>
            <Image
              className={styles["page-stack-left__image"]}
              src={props.blockData.block_properties.image}
              alt="landing-image"
            ></Image>
          </Box>
          <Box className={styles["page-stack-right"]}>
            <Box className={styles["page-title"]}>
              {props.blockData.block_properties.title}
            </Box>
            <Box className={styles["page-desc"]}>
              {props.blockData.block_properties.description}
            </Box>
            <Button
              onClick={() => generateTesterID()}
              isLoading={loadingTesterId}
              loadingText="Generating ID"
            >
              {props.blockData.block_properties.action_button_text}
            </Button>
          </Box>
        </HStack>
      </Box>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    study: state.study.study,
    block: state.block.block,
  };
};

const mapDispatchToProp = (dispatch: any) => {
  return {
    updateBlock: (payload: any) => {
      dispatch(updateBlock(payload));
    },
    updateTesterID: (payload: any) => {
      dispatch(updateTesterID(payload));
    },
    updateTesterName: (payload: any) => {
      dispatch(updateTesterName(payload));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProp);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(WelcomePage);
