import { FC, useEffect, useState } from "react";
import GetUserCamera from "../GetUserCamera/GetUserCamera";
import styles from "./FacialCoding.module.css";
// declare var facialEye: any;

interface FacialCodingProps {}

const FacialCoding: FC<FacialCodingProps> = () => {
  // const [loadSDK, setLoadSDK] = useState(true);

  // useEffect(() => {
  //   if (loadSDK) {
  //     let error = facialEye.init_fcet("", "");
  //     setLoadSDK(false);
  //     error.then((error: any) => {
  //       console.log("error is: ", error);
  //     });
  //   }
  // }, [loadSDK]);

  return (
    <div className={styles.FacialCoding} data-testid="FacialCoding">
      <GetUserCamera />
    </div>
  );
};

export default FacialCoding;
