import React, { FC } from "react";
import styles from "./ThankYouPage.module.css";
import { Box, HStack, Image } from "@chakra-ui/react";
import { connect, ConnectedProps } from "react-redux";

interface ThankYouPageProps extends PropsFromRedux {}

const ThankYouPage: FC<ThankYouPageProps> = (props: any) => {
  return (
    <div className={styles.ThankYouPage} data-testid="ThankYouPage">
      <Box>
        <HStack className={styles["page-stack"]}>
          <Box className={styles["page-stack-left"]}>
            <Image
              className={styles["page-stack-left__image"]}
              src={props.block.block_properties.image}
              alt="landing-image"
            ></Image>
          </Box>
          <Box className={styles["page-stack-right"]}>
            <Box className={styles["page-title"]}>
              {props.block.block_properties.title}
            </Box>
            <Box className={styles["page-desc"]}>
              {props.block.block_properties.description}
            </Box>
          </Box>
        </HStack>
      </Box>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    study: state.study.study,
    block: state.block.block,
  };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ThankYouPage);
