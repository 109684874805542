import React, { FC } from "react";
import { Box, Button, Center, Heading, VStack } from "@chakra-ui/react";
import styles from "./SurveyComponent.module.css";
import { connect, ConnectedProps } from "react-redux";
import { updateBlock } from "../../../reducers/block";
import blockService from "../../../services/block.service";

interface SurveyComponentProps extends PropsFromRedux {}

const SurveyComponent: FC<SurveyComponentProps> = (props: any) => {
  const getNextBlock = () => {
    blockService
      .getNextBlock(props.study.study_id, props.block.block_id)
      .then((blockRes: any) => {
        props.updateBlock(blockRes.data.data.block);
        console.log(blockRes);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  return (
    <div className={styles.SurveyComponent} data-testid="SurveyComponent">
      <Box>
        <Center h="100vh">
          <VStack>
            <Heading>Survey Component</Heading>
            <Button onClick={() => getNextBlock()}>Next</Button>
          </VStack>
        </Center>
      </Box>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    study: state.study.study,
    block: state.block.block,
  };
};

const mapDispatchToProp = (dispatch: any) => {
  return {
    updateBlock: (payload: any) => {
      dispatch(updateBlock(payload));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProp);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(SurveyComponent);
