import { configureStore } from "@reduxjs/toolkit";
import { testerReducer } from "./allReducer";
import { studyReducer } from "./allReducer";
import { blockReducer } from "./allReducer";

export const store = configureStore({
  reducer: {
    tester: testerReducer,
    study: studyReducer,
    block: blockReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
