import _ from "lodash";

class UtilityService {
  isLocal() {
    return _.includes(window.location.host, "localhost:3000");
  }

  getENV() {
    const hosturl = window.location.host.split(".");
    if (_.includes(hosturl, "staging")) {
      return "staging";
    } else if (_.includes(hosturl, "prestage")) {
      return "prestage";
    } else if (_.includes(hosturl, "dev")) {
      return "dev";
    } else if (_.includes(hosturl, "localhost:3000")) {
      return "dev";
    } else if (_.includes(hosturl, "qa")) {
      return "qa";
    } else {
      return "prod";
    }
  }
}

export default new UtilityService();
